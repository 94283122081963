define("lead-dashboard/templates/components/footer-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QoMYneml",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"footer-content\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"created-at\"],[9],[0,\"\\n      \"],[7,\"article\"],[11,\"class\",\"mapc-general\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"http://www.mapc.org\"],[11,\"class\",\"logo-image\"],[9],[0,\"\\n          \"],[7,\"img\"],[11,\"src\",\"/assets/images/mapc-logo.svg\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"mapc-logo-text\"],[9],[0,\"\\n          This dashboard was designed and built by MAPC's Digital Services Group and the Clean Energy Department.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"nav\"],[11,\"class\",\"footer-nav\"],[9],[0,\"\\n      \"],[7,\"ul\"],[9],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"learn-more\"],null,{\"statements\":[[0,\"Learn More\"]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[4,\"link-to\",[\"methodology\"],null,{\"statements\":[[0,\"Methodology\"]],\"parameters\":[]},null],[10],[0,\"\\n        \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"https://www.mapc.org/net-zero\"],[9],[0,\"Get Plugged In\"],[10],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/footer-content.hbs"
    }
  });

  _exports.default = _default;
});