define("lead-dashboard/templates/components/spider-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i1SR5iH3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"article\"],[11,\"class\",\"component spider-chart\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[27,\"normalize-slug\",[[23,[\"municipality\"]]],null]],[11,\"class\",\"chart\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/spider-chart.hbs"
    }
  });

  _exports.default = _default;
});