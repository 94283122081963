define("lead-dashboard/templates/components/pie-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ycj24rmI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"article\"],[11,\"class\",\"component pie-chart\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"tooltip-holder\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[27,\"normalize-slug\",[[23,[\"title\"]]],null]],[11,\"class\",\"chart\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"legend\"],[9],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/pie-chart.hbs"
    }
  });

  _exports.default = _default;
});