define("lead-dashboard/utils/sectors", ["exports", "lead-dashboard/utils/capitalize"], function (_exports, _capitalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.sectorRouteMap = void 0;
  const sectors = ['residential', 'commercial', 'industrial'];
  const sectorRouteMap = sectors.map(sector => {
    return {
      name: (0, _capitalize.default)(sector),
      route: `city.${sector}`
    };
  });
  _exports.sectorRouteMap = sectorRouteMap;
  var _default = sectors;
  _exports.default = _default;
});