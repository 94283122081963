define("lead-dashboard/templates/city/commercial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/W2BhBsz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"route city-commercial\"],[9],[0,\"\\n\\n  \"],[1,[27,\"sector-layout\",null,[[\"sector\",\"municipality\",\"municipalities\",\"sectorData\",\"muniSectorData\",\"valueMap\",\"criteria\",\"criteriaName\",\"criteriaColumn\",\"action\"],[[23,[\"sector\"]],[23,[\"municipality\"]],[23,[\"municipalities\"]],[23,[\"sectorData\"]],[23,[\"muniSectorData\"]],[23,[\"valueMap\"]],[23,[\"criteria\"]],[23,[\"criteriaName\"]],[23,[\"criteriaColumn\"]],\"fetchDataFor\"]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/city/commercial.hbs"
    }
  });

  _exports.default = _default;
});