define("lead-dashboard/templates/components/no-data-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mSO+xyDl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"component no-data-message\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    We do not have the data necessary for generating estimates for \"],[1,[21,\"municipality\"],false],[0,\"'s \"],[1,[21,\"sector\"],false],[0,\" sector. This could mean that there is\\n    no \"],[1,[21,\"sector\"],false],[0,\" sector in \"],[1,[21,\"municipality\"],false],[0,\" or that \"],[1,[21,\"municipality\"],false],[0,\"'s\\n    \"],[1,[21,\"sector\"],false],[0,\" sector data was not published in the sources used in our\\n    methodology.\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    For more information about how we calculate our estimates, please read our \\n    \"],[4,\"link-to\",[\"methodology\"],[[\"class\"],[\"styled\"]],{\"statements\":[[0,\"methodology\"]],\"parameters\":[]},null],[0,\" page.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/no-data-message.hbs"
    }
  });

  _exports.default = _default;
});