define("lead-dashboard/helpers/acronyze", ["exports", "lead-dashboard/utils/acronyze"], function (_exports, _acronyze2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.acronyze = acronyze;
  _exports.default = void 0;

  function acronyze(params) {
    return params[0] === undefined ? "" : (0, _acronyze2.default)(params[0]);
  }

  var _default = Ember.Helper.helper(acronyze);

  _exports.default = _default;
});