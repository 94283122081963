define("lead-dashboard/utils/max-row-by-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = maxRowByKey;

  function maxRowByKey(arr, key) {
    let max = -1;
    let maxIndex = null;
    arr.forEach((row, index) => {
      if (row[key] > max) {
        max = row[key];
        maxIndex = index;
      }
    });
    return {
      row: arr[maxIndex],
      index: maxIndex
    };
  }
});