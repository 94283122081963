define("lead-dashboard/utils/slug", ["exports", "lead-dashboard/utils/capitalize"], function (_exports, _capitalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = slug;

  function slug(value) {
    return {
      normalize() {
        return (value || '').toLowerCase().split(' ').join('-');
      },

      denormalize() {
        return (value || '').split('-').map(_capitalize.default).join(' ');
      }

    };
  }
});