define("lead-dashboard/helpers/capitalize", ["exports", "lead-dashboard/utils/capitalize"], function (_exports, _capitalize2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalize = capitalize;
  _exports.default = void 0;

  function capitalize(params) {
    return (0, _capitalize2.default)(params[0]);
  }

  var _default = Ember.Helper.helper(capitalize);

  _exports.default = _default;
});