define("lead-dashboard/controllers/city/index", ["exports", "ember-data", "ember-copy", "@ember-decorators/service", "@ember-decorators/controller", "@ember-decorators/object", "@ember-decorators/object/computed", "lead-dashboard/utils/slug", "lead-dashboard/utils/sectors", "lead-dashboard/utils/fuel-types"], function (_exports, _emberData, _emberCopy, _service, _controller, _object, _computed, _slug, _sectors2, _fuelTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _decorate(decorators, factory, superClass) { var r = factory(function initialize(O) { _initializeInstanceElements(O, decorated.elements); }, superClass); var decorated = _decorateClass(_coalesceClassElements(r.d.map(_createElementDescriptor)), decorators); _initializeClassElements(r.F, decorated.elements); return _runClassFinishers(r.F, decorated.finishers); }

  function _createElementDescriptor(def) { var key = _toPropertyKey(def.key); var descriptor; if (def.kind === "method") { descriptor = { value: def.value, writable: true, configurable: true, enumerable: false }; Object.defineProperty(def.value, "name", { value: typeof key === "symbol" ? "" : key, configurable: true }); } else if (def.kind === "get") { descriptor = { get: def.value, configurable: true, enumerable: false }; } else if (def.kind === "set") { descriptor = { set: def.value, configurable: true, enumerable: false }; } else if (def.kind === "field") { descriptor = { configurable: true, writable: true, enumerable: true }; } var element = { kind: def.kind === "field" ? "field" : "method", key: key, placement: def.static ? "static" : def.kind === "field" ? "own" : "prototype", descriptor: descriptor }; if (def.decorators) element.decorators = def.decorators; if (def.kind === "field") element.initializer = def.value; return element; }

  function _coalesceGetterSetter(element, other) { if (element.descriptor.get !== undefined) { other.descriptor.get = element.descriptor.get; } else { other.descriptor.set = element.descriptor.set; } }

  function _coalesceClassElements(elements) { var newElements = []; var isSameElement = function (other) { return other.kind === "method" && other.key === element.key && other.placement === element.placement; }; for (var i = 0; i < elements.length; i++) { var element = elements[i]; var other; if (element.kind === "method" && (other = newElements.find(isSameElement))) { if (_isDataDescriptor(element.descriptor) || _isDataDescriptor(other.descriptor)) { if (_hasDecorators(element) || _hasDecorators(other)) { throw new ReferenceError("Duplicated methods (" + element.key + ") can't be decorated."); } other.descriptor = element.descriptor; } else { if (_hasDecorators(element)) { if (_hasDecorators(other)) { throw new ReferenceError("Decorators can't be placed on different accessors with for " + "the same property (" + element.key + ")."); } other.decorators = element.decorators; } _coalesceGetterSetter(element, other); } } else { newElements.push(element); } } return newElements; }

  function _hasDecorators(element) { return element.decorators && element.decorators.length; }

  function _isDataDescriptor(desc) { return desc !== undefined && !(desc.value === undefined && desc.writable === undefined); }

  function _initializeClassElements(F, elements) { var proto = F.prototype; ["method", "field"].forEach(function (kind) { elements.forEach(function (element) { var placement = element.placement; if (element.kind === kind && (placement === "static" || placement === "prototype")) { var receiver = placement === "static" ? F : proto; _defineClassElement(receiver, element); } }); }); }

  function _initializeInstanceElements(O, elements) { ["method", "field"].forEach(function (kind) { elements.forEach(function (element) { if (element.kind === kind && element.placement === "own") { _defineClassElement(O, element); } }); }); }

  function _defineClassElement(receiver, element) { var descriptor = element.descriptor; if (element.kind === "field") { var initializer = element.initializer; descriptor = { enumerable: descriptor.enumerable, writable: descriptor.writable, configurable: descriptor.configurable, value: initializer === void 0 ? void 0 : initializer.call(receiver) }; } Object.defineProperty(receiver, element.key, descriptor); }

  function _decorateClass(elements, decorators) { var newElements = []; var finishers = []; var placements = { static: [], prototype: [], own: [] }; elements.forEach(function (element) { _addElementPlacement(element, placements); }); elements.forEach(function (element) { if (!_hasDecorators(element)) return newElements.push(element); var elementFinishersExtras = _decorateElement(element, placements); newElements.push(elementFinishersExtras.element); newElements.push.apply(newElements, elementFinishersExtras.extras); finishers.push.apply(finishers, elementFinishersExtras.finishers); }); if (!decorators) { return { elements: newElements, finishers: finishers }; } var result = _decorateConstructor(newElements, decorators); finishers.push.apply(finishers, result.finishers); result.finishers = finishers; return result; }

  function _addElementPlacement(element, placements, silent) { var keys = placements[element.placement]; if (!silent && keys.indexOf(element.key) !== -1) { throw new TypeError("Duplicated element (" + element.key + ")"); } keys.push(element.key); }

  function _decorateElement(element, placements) { var extras = []; var finishers = []; for (var decorators = element.decorators, i = decorators.length - 1; i >= 0; i--) { var keys = placements[element.placement]; keys.splice(keys.indexOf(element.key), 1); var elementObject = _fromElementDescriptor(element); var elementFinisherExtras = _toElementFinisherExtras((0, decorators[i])(elementObject) || elementObject); element = elementFinisherExtras.element; _addElementPlacement(element, placements); if (elementFinisherExtras.finisher) { finishers.push(elementFinisherExtras.finisher); } var newExtras = elementFinisherExtras.extras; if (newExtras) { for (var j = 0; j < newExtras.length; j++) { _addElementPlacement(newExtras[j], placements); } extras.push.apply(extras, newExtras); } } return { element: element, finishers: finishers, extras: extras }; }

  function _decorateConstructor(elements, decorators) { var finishers = []; for (var i = decorators.length - 1; i >= 0; i--) { var obj = _fromClassDescriptor(elements); var elementsAndFinisher = _toClassDescriptor((0, decorators[i])(obj) || obj); if (elementsAndFinisher.finisher !== undefined) { finishers.push(elementsAndFinisher.finisher); } if (elementsAndFinisher.elements !== undefined) { elements = elementsAndFinisher.elements; for (var j = 0; j < elements.length - 1; j++) { for (var k = j + 1; k < elements.length; k++) { if (elements[j].key === elements[k].key && elements[j].placement === elements[k].placement) { throw new TypeError("Duplicated element (" + elements[j].key + ")"); } } } } } return { elements: elements, finishers: finishers }; }

  function _fromElementDescriptor(element) { var obj = { kind: element.kind, key: element.key, placement: element.placement, descriptor: element.descriptor }; var desc = { value: "Descriptor", configurable: true }; Object.defineProperty(obj, Symbol.toStringTag, desc); if (element.kind === "field") obj.initializer = element.initializer; return obj; }

  function _toElementDescriptors(elementObjects) { if (elementObjects === undefined) return; return _toArray(elementObjects).map(function (elementObject) { var element = _toElementDescriptor(elementObject); _disallowProperty(elementObject, "finisher", "An element descriptor"); _disallowProperty(elementObject, "extras", "An element descriptor"); return element; }); }

  function _toElementDescriptor(elementObject) { var kind = String(elementObject.kind); if (kind !== "method" && kind !== "field") { throw new TypeError('An element descriptor\'s .kind property must be either "method" or' + ' "field", but a decorator created an element descriptor with' + ' .kind "' + kind + '"'); } var key = _toPropertyKey(elementObject.key); var placement = String(elementObject.placement); if (placement !== "static" && placement !== "prototype" && placement !== "own") { throw new TypeError('An element descriptor\'s .placement property must be one of "static",' + ' "prototype" or "own", but a decorator created an element descriptor' + ' with .placement "' + placement + '"'); } var descriptor = elementObject.descriptor; _disallowProperty(elementObject, "elements", "An element descriptor"); var element = { kind: kind, key: key, placement: placement, descriptor: Object.assign({}, descriptor) }; if (kind !== "field") { _disallowProperty(elementObject, "initializer", "A method descriptor"); } else { _disallowProperty(descriptor, "get", "The property descriptor of a field descriptor"); _disallowProperty(descriptor, "set", "The property descriptor of a field descriptor"); _disallowProperty(descriptor, "value", "The property descriptor of a field descriptor"); element.initializer = elementObject.initializer; } return element; }

  function _toElementFinisherExtras(elementObject) { var element = _toElementDescriptor(elementObject); var finisher = _optionalCallableProperty(elementObject, "finisher"); var extras = _toElementDescriptors(elementObject.extras); return { element: element, finisher: finisher, extras: extras }; }

  function _fromClassDescriptor(elements) { var obj = { kind: "class", elements: elements.map(_fromElementDescriptor) }; var desc = { value: "Descriptor", configurable: true }; Object.defineProperty(obj, Symbol.toStringTag, desc); return obj; }

  function _toClassDescriptor(obj) { var kind = String(obj.kind); if (kind !== "class") { throw new TypeError('A class descriptor\'s .kind property must be "class", but a decorator' + ' created a class descriptor with .kind "' + kind + '"'); } _disallowProperty(obj, "key", "A class descriptor"); _disallowProperty(obj, "placement", "A class descriptor"); _disallowProperty(obj, "descriptor", "A class descriptor"); _disallowProperty(obj, "initializer", "A class descriptor"); _disallowProperty(obj, "extras", "A class descriptor"); var finisher = _optionalCallableProperty(obj, "finisher"); var elements = _toElementDescriptors(obj.elements); return { elements: elements, finisher: finisher }; }

  function _disallowProperty(obj, name, objectType) { if (obj[name] !== undefined) { throw new TypeError(objectType + " can't have a ." + name + " property."); } }

  function _optionalCallableProperty(obj, name) { var value = obj[name]; if (value !== undefined && typeof value !== "function") { throw new TypeError("Expected '" + name + "' to be a function"); } return value; }

  function _runClassFinishers(constructor, finishers) { for (var i = 0; i < finishers.length; i++) { var newConstructor = (0, finishers[i])(constructor); if (newConstructor !== undefined) { if (typeof newConstructor !== "function") { throw new TypeError("Finishers must return a constructor."); } constructor = newConstructor; } } return constructor; }

  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }

  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = _decorate(null, function (_initialize, _EmberController) {
    class _class extends _EmberController {
      /**
       * Controllers
       */

      /**
       * Services
       */

      /**
       * Members
       */

      /**
       * Methods
       */
      constructor() {
        super(...arguments);

        _initialize(this);

        this.get('municipalityList').listFor().then(response => {
          const municipalities = response.rows.map(row => row.municipal).sort();
          this.set('municipalities', municipalities);
          this.send('compareTo', this.randomMunicipality());
        });
      }

    }

    return {
      F: _class,
      d: [{
        kind: "field",
        decorators: [_controller.controller],
        key: "city",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.service],
        key: "carto",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.service],
        key: "router",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.service],
        key: "municipalityList",
        value: void 0
      }, {
        kind: "field",
        key: "comparingTo",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "municipalities",

        value() {
          return [];
        }

      }, {
        kind: "field",
        key: "fuelNames",

        value() {
          return Object.values(_fuelTypes.fuelTypesMap);
        }

      }, {
        kind: "field",
        decorators: [(0, _computed.reads)('model.municipality')],
        key: "municipality",
        value: void 0
      }, {
        kind: "get",
        decorators: [_object.computed],
        key: "sectors",

        value() {
          const _sectors = _sectors2.default;

          _sectors.unshift('total');

          return _sectors;
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('model')],
        key: "fuelTypeData",

        value() {
          const munged = this.munger(this.get('model'), true);
          const fuelTypeData = munged.map(fuelType => {
            if (fuelType.type.toLowerCase() === 'electricity') {
              fuelType.footnote = true;
            }

            return fuelType;
          });
          return fuelTypeData;
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('fuelTypeData')],
        key: "fuelPercentages",

        value() {
          const fuelTypeData = this.get('fuelTypeData');
          return {
            emissions: fuelTypeData.map(type => type.sectors[0].emissionsPercentage),
            consumption: fuelTypeData.map(type => type.sectors[0].consumptionPercentage)
          };
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('model', 'sectors', 'fuelTypeData')],
        key: "totalEmissions",

        value() {
          const fuelTypeData = this.get('fuelTypeData');
          const totals = fuelTypeData.map(type => type.sectors).reduce((a, b) => a.concat(b)).filter(row => row.sector === 'total');
          const totalEmissions = totals.reduce((x, total) => x + total.emissions, 0);
          return totalEmissions;
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('fuelTypeData')],
        key: "largestEmitter",

        value() {
          const fuelTypeData = this.get('fuelTypeData');
          const sectorTotals = {};
          fuelTypeData.forEach(type => {
            type.sectors.filter(x => x.sector !== 'total').forEach(sectorData => {
              if (!sectorTotals[sectorData.sector]) {
                sectorTotals[sectorData.sector] = 0;
              }

              sectorTotals[sectorData.sector] += sectorData.emissionsPercentage;
            });
          });
          let max = -1;
          let largestEmitter = null;
          Object.keys(sectorTotals).forEach(sector => {
            if (sectorTotals[sector] > max) {
              max = sectorTotals[sector];
              largestEmitter = sector;
            }
          });
          this.set('largestEmitterPerc', max);
          return largestEmitter;
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('carto', 'municipality')],
        key: "populationCensus",

        value() {
          const carto = this.get('carto');
          const municipality = this.get('municipality');
          return _emberData.default.PromiseObject.create({
            promise: carto.populationFor(municipality).then(response => {
              return {
                estimate: response.rows[0].pop_est,
                year: response.rows[0].years
              };
            })
          });
        }

      }, {
        kind: "get",
        decorators: [(0, _object.computed)('model.commercial.rows.[]')],
        key: "latestYear",

        value() {
          return Math.max(...(this.get('model.commercial.rows') || []).map(row => row.year));
        }

      }, {
        kind: "method",
        key: "randomMunicipality",

        value(replace = true) {
          const _randomMunicipality = this.get('_randomMunicipality');

          const municipalities = this.get('municipalities');
          const rand = Math.floor(Math.random() * municipalities.length);
          const municipality = municipalities[rand];

          if (replace || _randomMunicipality === null) {
            this.set('_randomMunicipality', municipality);
            return municipality;
          }

          return _randomMunicipality;
        }

      }, {
        kind: "method",
        key: "munger",

        value(_model, log = false) {
          if (!this.get('sectors')) return;
          const model = (0, _emberCopy.copy)(_model, true);
          const sectors = this.get('sectors').filter(sector => sector !== 'total');
          const latestYear = this.get('latestYear');
          const munged = {};
          sectors.forEach(sector => {
            let subModel = (model[sector].rows || []).filter(row => !row.year || row.year === latestYear);
            let aggregatedData = {};

            if (subModel.length === 0) {
              _fuelTypes.fuelTypes.forEach(type => {
                ['con_mmbtu', 'emissions_co2', 'exp_dollar'].forEach(col => {
                  aggregatedData[`${type}_${col}`] = 0;
                });
              });
            } else {
              if (sector === 'residential') {
                subModel = subModel.filter(row => row.hu_type !== 'total');
              }

              aggregatedData = subModel.reduce((aggregate, current) => {
                Object.keys(aggregate).forEach(key => {
                  aggregate[key] += parseFloat(current[key]) || 0;
                });
                return aggregate;
              });
            }

            munged[sector] = aggregatedData;
          });

          const data = _fuelTypes.fuelTypes.map(_type => {
            let sectorData = sectors.map(_sector => {
              return {
                sector: String(_sector),
                consumption: munged[_sector][`${_type}_con_mmbtu`],
                emissions: munged[_sector][`${_type}_emissions_co2`],
                cost: munged[_sector][`${_type}_exp_dollar`]
              };
            }); // Make copy of first column since it will be used by reference in the reducer

            const original = Object.assign({}, sectorData[0]); // Calculate the total column

            sectorData.push(sectorData.reduce((aggregate, current) => {
              Object.keys(aggregate).filter(key => key !== 'sector').forEach(key => {
                aggregate[key] += current[key];
              });
              return aggregate;
            })); // Restore column back to its original state

            sectorData[0] = original;
            sectorData[sectorData.length - 1].sector = 'total';
            sectorData.unshift(sectorData.pop());
            return {
              type: _fuelTypes.fuelTypesMap[_type],
              sectors: sectorData
            };
          }); // Sum the consumption values from the 'total' column


          const totalConsumption = data.map(datum => datum.sectors[0].consumption).reduce((a, b) => a + b);
          const totalEmissions = data.map(datum => datum.sectors[0].emissions).reduce((a, b) => a + b); // Normalize the data

          data.forEach(datum => {
            datum.sectors.forEach(sector => {
              sector.consumption /= totalConsumption / 100;
              sector.emissions /= totalEmissions / 100;
              Object.keys(sector).filter(key => key !== 'sector').forEach(key => {
                // Show one decimal place if the value is less than 1
                let roundFactor = 1;
                if (sector[key] % 1 === sector[key]) roundFactor = 10;
                sector[`${key}Percentage`] = Math.round(sector[key] * roundFactor) / roundFactor;
              });
              sector.consumption /= 100 / totalConsumption;
              sector.emissions /= 100 / totalEmissions;
            });
          });
          return data;
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "compareTo",

        value(_comparingTo) {
          const sectorPromises = this.get('carto').allSectorDataFor(_comparingTo);
          Ember.RSVP.hash(sectorPromises.sectorData).then(response => {
            const munged = this.munger(response);

            if (munged) {
              const comparingTo = {
                municipality: _comparingTo,
                emissions: munged.map(row => row.sectors[0].emissionsPercentage),
                consumption: munged.map(row => row.sectors[0].consumptionPercentage)
              };
              this.set('comparingTo', comparingTo);
            }
          });
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeMunicipality",

        value(municipality) {
          this.get('router').transitionTo('city.index', (0, _slug.default)(municipality).normalize());
          this.send('compareTo', this.randomMunicipality());
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "downloadTableData",

        value() {
          const fuelTypeData = this.get('fuelTypeData');
          const municipality = this.get('municipality');
          const flattened = fuelTypeData.map(typeSet => {
            return typeSet.sectors.map(sector => {
              sector.fuel_type = typeSet.type.toLowerCase();
              return sector;
            });
          });
          const reduced = flattened.reduce((a, b) => a.concat(b));
          const renamed = reduced.map(row => {
            return {
              fuel_type_index: row.fuel_type,
              sector_index: row.sector,
              consumption_total_mmbtu: row.consumption,
              consumption_percentage: row.consumptionPercentage / 100,
              emissions_total_lbs_CO2e: row.emissions,
              emissions_percentage: row.emissionsPercentage / 100,
              cost_dollars: row.cost
            };
          });
          const csvHeader = "data:text/csv;charset=utf-8,";
          const documentHeader = Object.keys(renamed[0]);
          const documentRows = renamed.map(row => Object.keys(row).map(key => row[key]));
          const documentStructure = [[documentHeader], documentRows].reduce((a, b) => a.concat(b));
          const documentBody = documentStructure.reduce((a, b) => `${a}\n${b}`);
          const csvFile = csvHeader + documentBody;
          const encoded = encodeURI(csvFile);
          const link = document.createElement('a');
          link.setAttribute('href', encoded);
          link.setAttribute('download', `${municipality.toLowerCase()}_at-a-glance_data.csv`);
          document.body.appendChild(link);
          link.click();
        }

      }]
    };
  }, Ember.Controller);

  _exports.default = _default;
});