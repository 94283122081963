define("lead-dashboard/templates/components/stacked-area-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "joaYlqGA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"component chart stacked-area-chart\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[28,[[21,\"__chartID\"],\"-tooltip-holder\"]]],[11,\"class\",\"tooltip-holder\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"svg-wrapper\"],[9],[0,\"\\n    \"],[7,\"svg\"],[12,\"id\",[21,\"__chartID\"]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"id\",[28,[[21,\"__chartID\"],\"-legend\"]]],[11,\"class\",\"legend\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/stacked-area-chart.hbs"
    }
  });

  _exports.default = _default;
});