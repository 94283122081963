define("lead-dashboard/templates/components/sector-analysis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ebRqvUNk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\"],[11,\"class\",\"component sector-analysis\"],[9],[0,\"\\n  The \"],[1,[27,\"lowercase\",[[23,[\"criteriaName\"]]],null],false],[0,\" type that consumes the most energy in \"],[1,[27,\"acronyze\",[[23,[\"municipality\"]]],null],false],[0,\" is \\n  \"],[1,[21,\"topConsumingName\"],false],[0,\". \"],[1,[21,\"topConsumingString\"],false],[0,\" up \"],[1,[21,\"topConsumingPercentage\"],false],[0,\"% of the \"],[1,[21,\"sector\"],false],[0,\" \\n  building consumption in \"],[1,[27,\"acronyze\",[[23,[\"municipality\"]]],null],false],[0,\". The \"],[1,[21,\"sector\"],false],[0,\" sector consumes more \"],[1,[21,\"topFuel\"],false],[0,\" than \\n  \"],[1,[21,\"otherFuels\"],false],[0,\". The \"],[1,[21,\"topEmissionsName\"],false],[0,\" \"],[1,[27,\"lowercase\",[[23,[\"criteriaName\"]]],null],false],[0,\" type accounts for \\n  \"],[1,[21,\"topEmissionsPercentage\"],false],[0,\"% of \"],[1,[21,\"sector\"],false],[0,\" greenhouse gas emissions in \"],[1,[27,\"acronyze\",[[23,[\"municipality\"]]],null],false],[0,\" annually.  \\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/sector-analysis.hbs"
    }
  });

  _exports.default = _default;
});