define("lead-dashboard/routes/city/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      const cityModel = this.modelFor('city');
      const sectors = Object.keys(cityModel.sectorData);
      const toPromise = {
        municipality: cityModel.municipality
      };
      sectors.forEach(sector => {
        toPromise[sector] = cityModel.sectorData[sector];
      });
      return Ember.RSVP.hash(toPromise);
    }

    activate() {
      const cityIndexController = this.controllerFor('city.index');
      cityIndexController.send('compareTo', cityIndexController.randomMunicipality(false));
    }

  }

  _exports.default = _default;
});