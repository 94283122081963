define("lead-dashboard/components/pie-chart", ["exports", "d3", "ember-copy", "@ember-decorators/service", "lead-dashboard/utils/slug", "lead-dashboard/utils/fuel-types"], function (_exports, _d, _emberCopy, _service, _slug, _fuelTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _decorate(decorators, factory, superClass) { var r = factory(function initialize(O) { _initializeInstanceElements(O, decorated.elements); }, superClass); var decorated = _decorateClass(_coalesceClassElements(r.d.map(_createElementDescriptor)), decorators); _initializeClassElements(r.F, decorated.elements); return _runClassFinishers(r.F, decorated.finishers); }

  function _createElementDescriptor(def) { var key = _toPropertyKey(def.key); var descriptor; if (def.kind === "method") { descriptor = { value: def.value, writable: true, configurable: true, enumerable: false }; Object.defineProperty(def.value, "name", { value: typeof key === "symbol" ? "" : key, configurable: true }); } else if (def.kind === "get") { descriptor = { get: def.value, configurable: true, enumerable: false }; } else if (def.kind === "set") { descriptor = { set: def.value, configurable: true, enumerable: false }; } else if (def.kind === "field") { descriptor = { configurable: true, writable: true, enumerable: true }; } var element = { kind: def.kind === "field" ? "field" : "method", key: key, placement: def.static ? "static" : def.kind === "field" ? "own" : "prototype", descriptor: descriptor }; if (def.decorators) element.decorators = def.decorators; if (def.kind === "field") element.initializer = def.value; return element; }

  function _coalesceGetterSetter(element, other) { if (element.descriptor.get !== undefined) { other.descriptor.get = element.descriptor.get; } else { other.descriptor.set = element.descriptor.set; } }

  function _coalesceClassElements(elements) { var newElements = []; var isSameElement = function (other) { return other.kind === "method" && other.key === element.key && other.placement === element.placement; }; for (var i = 0; i < elements.length; i++) { var element = elements[i]; var other; if (element.kind === "method" && (other = newElements.find(isSameElement))) { if (_isDataDescriptor(element.descriptor) || _isDataDescriptor(other.descriptor)) { if (_hasDecorators(element) || _hasDecorators(other)) { throw new ReferenceError("Duplicated methods (" + element.key + ") can't be decorated."); } other.descriptor = element.descriptor; } else { if (_hasDecorators(element)) { if (_hasDecorators(other)) { throw new ReferenceError("Decorators can't be placed on different accessors with for " + "the same property (" + element.key + ")."); } other.decorators = element.decorators; } _coalesceGetterSetter(element, other); } } else { newElements.push(element); } } return newElements; }

  function _hasDecorators(element) { return element.decorators && element.decorators.length; }

  function _isDataDescriptor(desc) { return desc !== undefined && !(desc.value === undefined && desc.writable === undefined); }

  function _initializeClassElements(F, elements) { var proto = F.prototype; ["method", "field"].forEach(function (kind) { elements.forEach(function (element) { var placement = element.placement; if (element.kind === kind && (placement === "static" || placement === "prototype")) { var receiver = placement === "static" ? F : proto; _defineClassElement(receiver, element); } }); }); }

  function _initializeInstanceElements(O, elements) { ["method", "field"].forEach(function (kind) { elements.forEach(function (element) { if (element.kind === kind && element.placement === "own") { _defineClassElement(O, element); } }); }); }

  function _defineClassElement(receiver, element) { var descriptor = element.descriptor; if (element.kind === "field") { var initializer = element.initializer; descriptor = { enumerable: descriptor.enumerable, writable: descriptor.writable, configurable: descriptor.configurable, value: initializer === void 0 ? void 0 : initializer.call(receiver) }; } Object.defineProperty(receiver, element.key, descriptor); }

  function _decorateClass(elements, decorators) { var newElements = []; var finishers = []; var placements = { static: [], prototype: [], own: [] }; elements.forEach(function (element) { _addElementPlacement(element, placements); }); elements.forEach(function (element) { if (!_hasDecorators(element)) return newElements.push(element); var elementFinishersExtras = _decorateElement(element, placements); newElements.push(elementFinishersExtras.element); newElements.push.apply(newElements, elementFinishersExtras.extras); finishers.push.apply(finishers, elementFinishersExtras.finishers); }); if (!decorators) { return { elements: newElements, finishers: finishers }; } var result = _decorateConstructor(newElements, decorators); finishers.push.apply(finishers, result.finishers); result.finishers = finishers; return result; }

  function _addElementPlacement(element, placements, silent) { var keys = placements[element.placement]; if (!silent && keys.indexOf(element.key) !== -1) { throw new TypeError("Duplicated element (" + element.key + ")"); } keys.push(element.key); }

  function _decorateElement(element, placements) { var extras = []; var finishers = []; for (var decorators = element.decorators, i = decorators.length - 1; i >= 0; i--) { var keys = placements[element.placement]; keys.splice(keys.indexOf(element.key), 1); var elementObject = _fromElementDescriptor(element); var elementFinisherExtras = _toElementFinisherExtras((0, decorators[i])(elementObject) || elementObject); element = elementFinisherExtras.element; _addElementPlacement(element, placements); if (elementFinisherExtras.finisher) { finishers.push(elementFinisherExtras.finisher); } var newExtras = elementFinisherExtras.extras; if (newExtras) { for (var j = 0; j < newExtras.length; j++) { _addElementPlacement(newExtras[j], placements); } extras.push.apply(extras, newExtras); } } return { element: element, finishers: finishers, extras: extras }; }

  function _decorateConstructor(elements, decorators) { var finishers = []; for (var i = decorators.length - 1; i >= 0; i--) { var obj = _fromClassDescriptor(elements); var elementsAndFinisher = _toClassDescriptor((0, decorators[i])(obj) || obj); if (elementsAndFinisher.finisher !== undefined) { finishers.push(elementsAndFinisher.finisher); } if (elementsAndFinisher.elements !== undefined) { elements = elementsAndFinisher.elements; for (var j = 0; j < elements.length - 1; j++) { for (var k = j + 1; k < elements.length; k++) { if (elements[j].key === elements[k].key && elements[j].placement === elements[k].placement) { throw new TypeError("Duplicated element (" + elements[j].key + ")"); } } } } } return { elements: elements, finishers: finishers }; }

  function _fromElementDescriptor(element) { var obj = { kind: element.kind, key: element.key, placement: element.placement, descriptor: element.descriptor }; var desc = { value: "Descriptor", configurable: true }; Object.defineProperty(obj, Symbol.toStringTag, desc); if (element.kind === "field") obj.initializer = element.initializer; return obj; }

  function _toElementDescriptors(elementObjects) { if (elementObjects === undefined) return; return _toArray(elementObjects).map(function (elementObject) { var element = _toElementDescriptor(elementObject); _disallowProperty(elementObject, "finisher", "An element descriptor"); _disallowProperty(elementObject, "extras", "An element descriptor"); return element; }); }

  function _toElementDescriptor(elementObject) { var kind = String(elementObject.kind); if (kind !== "method" && kind !== "field") { throw new TypeError('An element descriptor\'s .kind property must be either "method" or' + ' "field", but a decorator created an element descriptor with' + ' .kind "' + kind + '"'); } var key = _toPropertyKey(elementObject.key); var placement = String(elementObject.placement); if (placement !== "static" && placement !== "prototype" && placement !== "own") { throw new TypeError('An element descriptor\'s .placement property must be one of "static",' + ' "prototype" or "own", but a decorator created an element descriptor' + ' with .placement "' + placement + '"'); } var descriptor = elementObject.descriptor; _disallowProperty(elementObject, "elements", "An element descriptor"); var element = { kind: kind, key: key, placement: placement, descriptor: Object.assign({}, descriptor) }; if (kind !== "field") { _disallowProperty(elementObject, "initializer", "A method descriptor"); } else { _disallowProperty(descriptor, "get", "The property descriptor of a field descriptor"); _disallowProperty(descriptor, "set", "The property descriptor of a field descriptor"); _disallowProperty(descriptor, "value", "The property descriptor of a field descriptor"); element.initializer = elementObject.initializer; } return element; }

  function _toElementFinisherExtras(elementObject) { var element = _toElementDescriptor(elementObject); var finisher = _optionalCallableProperty(elementObject, "finisher"); var extras = _toElementDescriptors(elementObject.extras); return { element: element, finisher: finisher, extras: extras }; }

  function _fromClassDescriptor(elements) { var obj = { kind: "class", elements: elements.map(_fromElementDescriptor) }; var desc = { value: "Descriptor", configurable: true }; Object.defineProperty(obj, Symbol.toStringTag, desc); return obj; }

  function _toClassDescriptor(obj) { var kind = String(obj.kind); if (kind !== "class") { throw new TypeError('A class descriptor\'s .kind property must be "class", but a decorator' + ' created a class descriptor with .kind "' + kind + '"'); } _disallowProperty(obj, "key", "A class descriptor"); _disallowProperty(obj, "placement", "A class descriptor"); _disallowProperty(obj, "descriptor", "A class descriptor"); _disallowProperty(obj, "initializer", "A class descriptor"); _disallowProperty(obj, "extras", "A class descriptor"); var finisher = _optionalCallableProperty(obj, "finisher"); var elements = _toElementDescriptors(obj.elements); return { elements: elements, finisher: finisher }; }

  function _disallowProperty(obj, name, objectType) { if (obj[name] !== undefined) { throw new TypeError(objectType + " can't have a ." + name + " property."); } }

  function _optionalCallableProperty(obj, name) { var value = obj[name]; if (value !== undefined && typeof value !== "function") { throw new TypeError("Expected '" + name + "' to be a function"); } return value; }

  function _runClassFinishers(constructor, finishers) { for (var i = 0; i < finishers.length; i++) { var newConstructor = (0, finishers[i])(constructor); if (newConstructor !== undefined) { if (typeof newConstructor !== "function") { throw new TypeError("Finishers must return a constructor."); } constructor = newConstructor; } } return constructor; }

  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }

  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _get(target, property, receiver) { if (typeof Reflect !== "undefined" && Reflect.get) { _get = Reflect.get; } else { _get = function _get(target, property, receiver) { var base = _superPropBase(target, property); if (!base) return; var desc = Object.getOwnPropertyDescriptor(base, property); if (desc.get) { return desc.get.call(receiver); } return desc.value; }; } return _get(target, property, receiver || target); }

  function _superPropBase(object, property) { while (!Object.prototype.hasOwnProperty.call(object, property)) { object = _getPrototypeOf(object); if (object === null) break; } return object; }

  function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

  var _default = _decorate(null, function (_initialize, _EmberComponent) {
    class _class extends _EmberComponent {
      /**
       * Services
       */

      /**
       * Members
       */

      /**
       * Methods
       */
      constructor() {
        super(...arguments);

        _initialize(this);

        this.set('normalizedTitle', (0, _slug.default)(this.get('title')).normalize());
        this.calculateChartDimensions();
        Ember.$(window).resize(() => {
          Ember.run(() => this.calculateChartDimensions(true));
        });
      }

    }

    return {
      F: _class,
      d: [{
        kind: "field",
        decorators: [_service.service],
        key: "colorManager",
        value: void 0
      }, {
        kind: "field",
        key: "chartOptions",

        value() {
          return {
            width: 320,
            height: 320,
            numTicks: 3,
            tooltipDisplacement: 20,
            transitionDuration: 200
          };
        }

      }, {
        kind: "method",
        key: "calculateChartDimensions",

        value(render = false) {
          const chartOptions = this.get('chartOptions');
          const size = window.innerWidth > 670 ? 320 : window.innerWidth > 480 ? 240 : 180;
          chartOptions.width = size;
          chartOptions.height = size;
          this.set('chartOptions', chartOptions);

          if (render) {
            this.resizeSVGElement(size);
            this.didRender();
          }
        }

      }, {
        kind: "method",
        key: "resizeSVGElement",

        value(size) {
          const normalizedTitle = this.get('normalizedTitle');

          _d.default.select(`#${normalizedTitle}`).select('svg').attr('width', size).attr('height', size).select('g').attr('transform', `translate(${size / 2},${size / 2})`);
        }

      }, {
        kind: "method",
        key: "didInsertElement",

        value() {
          _get(_getPrototypeOf(_class.prototype), "didInsertElement", this).apply(this, arguments);

          const {
            width,
            height
          } = this.get('chartOptions');
          const normalizedTitle = this.get('normalizedTitle');

          _d.default.select(`#${normalizedTitle}`).append('svg').append('g');

          this.resizeSVGElement(Math.max(width, height));

          _d.default.select(`#${normalizedTitle}`).append('div').attr('class', 'legend').append('ul');

          _d.default.select('.tooltip-holder').append('div').attr('class', 'tooltip');
        }

      }, {
        kind: "method",
        key: "unitTransform",

        value(context, val) {
          const metric = context.get('metric');
          const transforms = {
            con_mmbtu: val => `${val} <span>mmbtu</span>`,
            emissions_co2: val => `${val} <span>lbs CO<small>2</small>e</span>`,
            exp_dollar: val => `<span>$</span>${val}`
          };
          return transforms[metric](val);
        }

      }, {
        kind: "method",
        key: "didRender",

        value() {
          //super.didRender(...arguments);
          const {
            width,
            height,
            transitionDuration,
            tooltipDisplacement
          } = this.get('chartOptions');
          const normalizedTitle = this.get('normalizedTitle');
          const colors = this.get('colorManager').colors;
          const metric = this.get('metric');
          const minDim = Math.min(width, height);

          const color = _d.default.scaleOrdinal([colors.orellow, colors.lightGreen, colors.blue]);

          const data = (0, _emberCopy.copy)(this.get('data'), true);

          const arc = _d.default.arc().innerRadius(minDim / 3.5).outerRadius(minDim / 2);

          const pie = _d.default.pie().sort(null).value(d => d.percent);

          const svg = _d.default.select(`#${normalizedTitle}`).select('svg').select('g');

          const legend = _d.default.select(`#${normalizedTitle}`).select('.legend').select('ul').selectAll('li').data(_fuelTypes.fuelTypes).enter().append('li');

          legend.append("span").attr('class', 'fuel-color').style('background', d => color(d));
          legend.append('span').attr('class', 'fuel-name').html(d => _fuelTypes.fuelTypesMap[d]);

          const tooltip = _d.default.select('.tooltip-holder').append('div').attr('class', 'tooltip');

          tooltip.append('div').attr('class', 'percent');
          const pathInfo = tooltip.append('div').attr('class', 'path-info');
          pathInfo.append('div').attr('class', 'fuel-type');
          pathInfo.append('div').attr('class', 'value');

          _fuelTypes.fuelTypes.forEach(type => data[0][`${type}_tot`] = 0); // Aggregate totals for each fuel type


          let totals = {};

          if (data.length > 1) {
            totals = data.reduce((aggregate, current) => {
              _fuelTypes.fuelTypes.forEach(type => aggregate[`${type}_tot`] += parseFloat(current[`${type}_${metric}`]));

              return aggregate;
            });
          } else {
            _fuelTypes.fuelTypes.forEach(type => totals[`${type}_tot`] = parseFloat(data[0][`${type}_${metric}`]));
          } // Get total for all fuel consumption


          data.cent = _fuelTypes.fuelTypes.map(type => totals[`${type}_tot`]).reduce((x, y) => x + (y || 0));

          const results = _fuelTypes.fuelTypes.map(type => {
            return {
              fuel_type: type,
              percent: totals[`${type}_tot`] / data.cent,
              value: totals[`${type}_tot`]
            };
          }); // Start composing chart


          let path = svg.selectAll('path').data(pie(results));
          path.enter().append('path').attr('d', arc).style('fill', d => color(d.data.fuel_type)).each(function (d) {
            this._current = d;
          }).on('mouseover', d => {
            const percent = Math.round(100 * d.data.percent);
            tooltip.select('.fuel-type').html(_fuelTypes.fuelTypesMap[d.data.fuel_type]);
            tooltip.select('.value').html(this.get('unitTransform')(this, Math.round(d.data.value).toLocaleString('en-us')));
            tooltip.select('.percent').html(String(percent) + '%').style('color', color(d.data.fuel_type));
            tooltip.style('display', 'block');
          }).on('mouseout', () => {
            tooltip.style('display', 'none');
          }).on('mousemove', function () {
            const mouseCoords = _d.default.mouse(this);

            tooltip.style('top', String(mouseCoords[1] - tooltipDisplacement * .75) + 'px').style('left', String(mouseCoords[0] + tooltipDisplacement * 1.5) + 'px');
          });
          path.transition().duration(transitionDuration).attrTween('d', function (a) {
            const i = _d.default.interpolate(this._current, a);

            this._current = i(0);
            return function (t) {
              return arc(i(t));
            };
          });
        }

      }]
    };
  }, Ember.Component);

  _exports.default = _default;
});