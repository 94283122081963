define("lead-dashboard/helpers/lowercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lowercase = lowercase;
  _exports.default = void 0;

  function lowercase(params) {
    return params[0].toLowerCase();
  }

  var _default = Ember.Helper.helper(lowercase);

  _exports.default = _default;
});