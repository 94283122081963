define("lead-dashboard/templates/components/header-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S1/J+eq+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[9],[0,\"\\n  \"],[7,\"h1\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],null,{\"statements\":[[0,\"      Local Energy Action Dashboard\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"em\"],[9],[0,\"By the Metropolitan Area Planning Council\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"nav\"],[11,\"class\",\"header-nav\"],[9],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"learn-more\"],null,{\"statements\":[[0,\"Learn More\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"methodology\"],null,{\"statements\":[[0,\"Methodology\"]],\"parameters\":[]},null],[10],[0,\"\\n      \"],[7,\"li\"],[9],[7,\"a\"],[11,\"href\",\"https://www.mapc.org/net-zero\"],[9],[0,\"Get Plugged In\"],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/header-content.hbs"
    }
  });

  _exports.default = _default;
});