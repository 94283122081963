define("lead-dashboard/utils/fuel-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fuelTypes = _exports.fuelTypesMap = _exports.default = void 0;
  const fuelTypes = ['elec', 'ng', 'foil'];
  _exports.fuelTypes = fuelTypes;
  const fuelTypesMap = {
    'elec': 'Electricity',
    'ng': 'Natural Gas',
    'foil': 'Heating Oil'
  };
  _exports.fuelTypesMap = fuelTypesMap;
  var _default = fuelTypes;
  _exports.default = _default;
});