define("lead-dashboard/helpers/normalize-slug", ["exports", "lead-dashboard/utils/slug"], function (_exports, _slug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeSlug = normalizeSlug;
  _exports.default = void 0;

  function normalizeSlug(params) {
    return (0, _slug.default)(params[0]).normalize();
  }

  var _default = Ember.Helper.helper(normalizeSlug);

  _exports.default = _default;
});