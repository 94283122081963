define("lead-dashboard/router", ["exports", "lead-dashboard/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('city', {
      path: 'cities/:municipality'
    }, function () {
      this.route('industrial');
      this.route('residential');
      this.route('commercial');
    });
    this.route('learn-more');
    this.route('methodology');
  });
  var _default = Router;
  _exports.default = _default;
});