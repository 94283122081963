define("lead-dashboard/templates/city", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RdcXcSU8",
    "block": "{\"symbols\":[\"sector\"],\"statements\":[[7,\"section\"],[11,\"class\",\"route city\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"city-nav\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"home-button-wrapper\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"home-button\"]],{\"statements\":[[0,\"        \"],[7,\"img\"],[11,\"src\",\"/assets/images/house.svg\"],[11,\"alt\",\"To Home page\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\\n    \"],[7,\"ul\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[4,\"link-to\",[\"city.index\",[23,[\"model\",\"municipality\"]]],null,{\"statements\":[[1,[27,\"acronyze\",[[23,[\"model\",\"municipality\"]]],null],false]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"sectors\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[9],[0,\"\\n          \"],[4,\"link-to\",[[22,1,[\"route\"]],[23,[\"model\",\"municipality\"]]],null,{\"statements\":[[1,[22,1,[\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"content-window\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[1,[21,\"footer-content\"],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/city.hbs"
    }
  });

  _exports.default = _default;
});