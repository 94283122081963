define("lead-dashboard/components/stacked-area-chart", ["exports", "d3", "@ember-decorators/service", "@ember-decorators/object", "lead-dashboard/utils/guid", "lead-dashboard/utils/capitalize", "lead-dashboard/utils/charts", "lead-dashboard/utils/fuel-types"], function (_exports, _d, _service, _object, _guid, _capitalize, _charts, _fuelTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _decorate(decorators, factory, superClass) { var r = factory(function initialize(O) { _initializeInstanceElements(O, decorated.elements); }, superClass); var decorated = _decorateClass(_coalesceClassElements(r.d.map(_createElementDescriptor)), decorators); _initializeClassElements(r.F, decorated.elements); return _runClassFinishers(r.F, decorated.finishers); }

  function _createElementDescriptor(def) { var key = _toPropertyKey(def.key); var descriptor; if (def.kind === "method") { descriptor = { value: def.value, writable: true, configurable: true, enumerable: false }; Object.defineProperty(def.value, "name", { value: typeof key === "symbol" ? "" : key, configurable: true }); } else if (def.kind === "get") { descriptor = { get: def.value, configurable: true, enumerable: false }; } else if (def.kind === "set") { descriptor = { set: def.value, configurable: true, enumerable: false }; } else if (def.kind === "field") { descriptor = { configurable: true, writable: true, enumerable: true }; } var element = { kind: def.kind === "field" ? "field" : "method", key: key, placement: def.static ? "static" : def.kind === "field" ? "own" : "prototype", descriptor: descriptor }; if (def.decorators) element.decorators = def.decorators; if (def.kind === "field") element.initializer = def.value; return element; }

  function _coalesceGetterSetter(element, other) { if (element.descriptor.get !== undefined) { other.descriptor.get = element.descriptor.get; } else { other.descriptor.set = element.descriptor.set; } }

  function _coalesceClassElements(elements) { var newElements = []; var isSameElement = function (other) { return other.kind === "method" && other.key === element.key && other.placement === element.placement; }; for (var i = 0; i < elements.length; i++) { var element = elements[i]; var other; if (element.kind === "method" && (other = newElements.find(isSameElement))) { if (_isDataDescriptor(element.descriptor) || _isDataDescriptor(other.descriptor)) { if (_hasDecorators(element) || _hasDecorators(other)) { throw new ReferenceError("Duplicated methods (" + element.key + ") can't be decorated."); } other.descriptor = element.descriptor; } else { if (_hasDecorators(element)) { if (_hasDecorators(other)) { throw new ReferenceError("Decorators can't be placed on different accessors with for " + "the same property (" + element.key + ")."); } other.decorators = element.decorators; } _coalesceGetterSetter(element, other); } } else { newElements.push(element); } } return newElements; }

  function _hasDecorators(element) { return element.decorators && element.decorators.length; }

  function _isDataDescriptor(desc) { return desc !== undefined && !(desc.value === undefined && desc.writable === undefined); }

  function _initializeClassElements(F, elements) { var proto = F.prototype; ["method", "field"].forEach(function (kind) { elements.forEach(function (element) { var placement = element.placement; if (element.kind === kind && (placement === "static" || placement === "prototype")) { var receiver = placement === "static" ? F : proto; _defineClassElement(receiver, element); } }); }); }

  function _initializeInstanceElements(O, elements) { ["method", "field"].forEach(function (kind) { elements.forEach(function (element) { if (element.kind === kind && element.placement === "own") { _defineClassElement(O, element); } }); }); }

  function _defineClassElement(receiver, element) { var descriptor = element.descriptor; if (element.kind === "field") { var initializer = element.initializer; descriptor = { enumerable: descriptor.enumerable, writable: descriptor.writable, configurable: descriptor.configurable, value: initializer === void 0 ? void 0 : initializer.call(receiver) }; } Object.defineProperty(receiver, element.key, descriptor); }

  function _decorateClass(elements, decorators) { var newElements = []; var finishers = []; var placements = { static: [], prototype: [], own: [] }; elements.forEach(function (element) { _addElementPlacement(element, placements); }); elements.forEach(function (element) { if (!_hasDecorators(element)) return newElements.push(element); var elementFinishersExtras = _decorateElement(element, placements); newElements.push(elementFinishersExtras.element); newElements.push.apply(newElements, elementFinishersExtras.extras); finishers.push.apply(finishers, elementFinishersExtras.finishers); }); if (!decorators) { return { elements: newElements, finishers: finishers }; } var result = _decorateConstructor(newElements, decorators); finishers.push.apply(finishers, result.finishers); result.finishers = finishers; return result; }

  function _addElementPlacement(element, placements, silent) { var keys = placements[element.placement]; if (!silent && keys.indexOf(element.key) !== -1) { throw new TypeError("Duplicated element (" + element.key + ")"); } keys.push(element.key); }

  function _decorateElement(element, placements) { var extras = []; var finishers = []; for (var decorators = element.decorators, i = decorators.length - 1; i >= 0; i--) { var keys = placements[element.placement]; keys.splice(keys.indexOf(element.key), 1); var elementObject = _fromElementDescriptor(element); var elementFinisherExtras = _toElementFinisherExtras((0, decorators[i])(elementObject) || elementObject); element = elementFinisherExtras.element; _addElementPlacement(element, placements); if (elementFinisherExtras.finisher) { finishers.push(elementFinisherExtras.finisher); } var newExtras = elementFinisherExtras.extras; if (newExtras) { for (var j = 0; j < newExtras.length; j++) { _addElementPlacement(newExtras[j], placements); } extras.push.apply(extras, newExtras); } } return { element: element, finishers: finishers, extras: extras }; }

  function _decorateConstructor(elements, decorators) { var finishers = []; for (var i = decorators.length - 1; i >= 0; i--) { var obj = _fromClassDescriptor(elements); var elementsAndFinisher = _toClassDescriptor((0, decorators[i])(obj) || obj); if (elementsAndFinisher.finisher !== undefined) { finishers.push(elementsAndFinisher.finisher); } if (elementsAndFinisher.elements !== undefined) { elements = elementsAndFinisher.elements; for (var j = 0; j < elements.length - 1; j++) { for (var k = j + 1; k < elements.length; k++) { if (elements[j].key === elements[k].key && elements[j].placement === elements[k].placement) { throw new TypeError("Duplicated element (" + elements[j].key + ")"); } } } } } return { elements: elements, finishers: finishers }; }

  function _fromElementDescriptor(element) { var obj = { kind: element.kind, key: element.key, placement: element.placement, descriptor: element.descriptor }; var desc = { value: "Descriptor", configurable: true }; Object.defineProperty(obj, Symbol.toStringTag, desc); if (element.kind === "field") obj.initializer = element.initializer; return obj; }

  function _toElementDescriptors(elementObjects) { if (elementObjects === undefined) return; return _toArray(elementObjects).map(function (elementObject) { var element = _toElementDescriptor(elementObject); _disallowProperty(elementObject, "finisher", "An element descriptor"); _disallowProperty(elementObject, "extras", "An element descriptor"); return element; }); }

  function _toElementDescriptor(elementObject) { var kind = String(elementObject.kind); if (kind !== "method" && kind !== "field") { throw new TypeError('An element descriptor\'s .kind property must be either "method" or' + ' "field", but a decorator created an element descriptor with' + ' .kind "' + kind + '"'); } var key = _toPropertyKey(elementObject.key); var placement = String(elementObject.placement); if (placement !== "static" && placement !== "prototype" && placement !== "own") { throw new TypeError('An element descriptor\'s .placement property must be one of "static",' + ' "prototype" or "own", but a decorator created an element descriptor' + ' with .placement "' + placement + '"'); } var descriptor = elementObject.descriptor; _disallowProperty(elementObject, "elements", "An element descriptor"); var element = { kind: kind, key: key, placement: placement, descriptor: Object.assign({}, descriptor) }; if (kind !== "field") { _disallowProperty(elementObject, "initializer", "A method descriptor"); } else { _disallowProperty(descriptor, "get", "The property descriptor of a field descriptor"); _disallowProperty(descriptor, "set", "The property descriptor of a field descriptor"); _disallowProperty(descriptor, "value", "The property descriptor of a field descriptor"); element.initializer = elementObject.initializer; } return element; }

  function _toElementFinisherExtras(elementObject) { var element = _toElementDescriptor(elementObject); var finisher = _optionalCallableProperty(elementObject, "finisher"); var extras = _toElementDescriptors(elementObject.extras); return { element: element, finisher: finisher, extras: extras }; }

  function _fromClassDescriptor(elements) { var obj = { kind: "class", elements: elements.map(_fromElementDescriptor) }; var desc = { value: "Descriptor", configurable: true }; Object.defineProperty(obj, Symbol.toStringTag, desc); return obj; }

  function _toClassDescriptor(obj) { var kind = String(obj.kind); if (kind !== "class") { throw new TypeError('A class descriptor\'s .kind property must be "class", but a decorator' + ' created a class descriptor with .kind "' + kind + '"'); } _disallowProperty(obj, "key", "A class descriptor"); _disallowProperty(obj, "placement", "A class descriptor"); _disallowProperty(obj, "descriptor", "A class descriptor"); _disallowProperty(obj, "initializer", "A class descriptor"); _disallowProperty(obj, "extras", "A class descriptor"); var finisher = _optionalCallableProperty(obj, "finisher"); var elements = _toElementDescriptors(obj.elements); return { elements: elements, finisher: finisher }; }

  function _disallowProperty(obj, name, objectType) { if (obj[name] !== undefined) { throw new TypeError(objectType + " can't have a ." + name + " property."); } }

  function _optionalCallableProperty(obj, name) { var value = obj[name]; if (value !== undefined && typeof value !== "function") { throw new TypeError("Expected '" + name + "' to be a function"); } return value; }

  function _runClassFinishers(constructor, finishers) { for (var i = 0; i < finishers.length; i++) { var newConstructor = (0, finishers[i])(constructor); if (newConstructor !== undefined) { if (typeof newConstructor !== "function") { throw new TypeError("Finishers must return a constructor."); } constructor = newConstructor; } } return constructor; }

  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }

  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }

  function _toArray(arr) { return _arrayWithHoles(arr) || _iterableToArray(arr) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _get(target, property, receiver) { if (typeof Reflect !== "undefined" && Reflect.get) { _get = Reflect.get; } else { _get = function _get(target, property, receiver) { var base = _superPropBase(target, property); if (!base) return; var desc = Object.getOwnPropertyDescriptor(base, property); if (desc.get) { return desc.get.call(receiver); } return desc.value; }; } return _get(target, property, receiver || target); }

  function _superPropBase(object, property) { while (!Object.prototype.hasOwnProperty.call(object, property)) { object = _getPrototypeOf(object); if (object === null) break; } return object; }

  function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }

  let StackedAreaChartComponent = _decorate(null, function (_initialize, _EmberComponent) {
    class StackedAreaChartComponent extends _EmberComponent {
      /**
       * Members
       */

      /**
       * Methods
       */
      constructor() {
        super(...arguments);

        _initialize(this);

        this.set('__chartID', `sac-${(0, _guid.default)()}`);
        const colorManager = this.get('colorManager');
        const {
          colors
        } = colorManager;
        this.set('colorMap', {
          'residential-elec': colors.orellow,
          'residential-ng': colorManager.offset(colors.orellow, .4),
          'commercial-elec': colors.lightGreen,
          'commercial-ng': colorManager.offset(colors.lightGreen, .4),
          'industrial-elec': colors.blue,
          'industrial-ng': colorManager.offset(colors.blue, .4)
        });
        const data = this.get('data');
        this.set('xAxis.ticks', data.uniqBy('x').length - 1);
      }

    }

    return {
      F: StackedAreaChartComponent,
      d: [{
        kind: "field",
        decorators: [_service.service],
        key: "colorManager",
        value: void 0
      }, {
        kind: "field",
        key: "container",

        value() {
          return {
            height: 450,
            width: 800
          };
        }

      }, {
        kind: "field",
        key: "defaultMargin",

        value() {
          return {
            top: 20,
            left: 60,
            right: 20,
            bottom: 50
          };
        }

      }, {
        kind: "field",
        key: "transitionDuration",

        value() {
          return 200;
        }

      }, {
        kind: "field",
        key: "tooltipDisplacement",

        value() {
          return 20;
        }

      }, {
        kind: "method",
        key: "didInsertElement",

        value() {
          _get(_getPrototypeOf(StackedAreaChartComponent.prototype), "didInsertElement", this).apply(this, arguments);

          const {
            width,
            height
          } = this.get('container');

          const __chartID = this.get('__chartID');

          const chart = _d.default.select(`#${__chartID}`).attr('preserveAspectRatio', 'xMinYMin meet').attr('viewBox', `0 0 ${width} ${height}`);

          const tooltip = _d.default.select(`#${__chartID}-tooltip-holder`).append('div').attr('class', 'tooltip');

          tooltip.append('div').attr('class', 'percent');
          const pathInfo = tooltip.append('div').attr('class', 'path-info');
          pathInfo.append('small').attr('class', 'sector');
          pathInfo.append('div').attr('class', 'fuel-type');
          pathInfo.append('div').attr('class', 'value');
          this.set('chart', chart);
          this.set('tooltip', tooltip);
          this.set('legend', _d.default.select(`#${__chartID}-legend`));
          this.renderChart();
        }

      }, {
        kind: "method",
        decorators: [(0, _object.observes)('data.[]', 'yAxis', 'xAxis')],
        key: "renderChart",

        value() {
          const chartData = this.get('data');
          const chart = this.get('chart');
          const colors = this.get('colorMap');
          const xAxisConf = this.get('xAxis');
          const yAxisConf = this.get('yAxis');
          const tooltip = this.get('tooltip');
          const bonusLeftMargin = (0, _charts.maxToMargin)(_d.default.max(chartData, d => d.y));
          const margin = Object.assign({}, this.defaultMargin, {
            left: this.defaultMargin.left + bonusLeftMargin
          });
          const width = this.container.width - margin.left - margin.right;
          const height = this.container.height - margin.top - margin.bottom;

          const x = _d.default.scaleLinear().range([0, width]);

          const y = _d.default.scaleLinear().range([height, 0]);

          const area = _d.default.area().x(d => x(d.data.x)).y0(d => y(d[0])).y1(d => y(d[1]));

          const existingKeys = chartData.map(d => d.z);
          const keyOrder = ['commercial-elec', 'commercial-ng', 'industrial-elec', 'industrial-ng', 'residential-elec', 'residential-ng'];
          const keys = keyOrder.filter(key => existingKeys.includes(key));

          const stack = _d.default.stack();

          stack.keys(keys);
          chart.selectAll('*').remove(); // Clear chart before drawing

          const gChart = chart.append('g').attr('transform', `translate(${margin.left},${margin.top})`);
          let data = chartData.reduce((acc, row) => {
            acc[row.x] = { ...(acc[row.x] || {}),
              ...{
                [row.z]: row.y + ((acc[row.x] || {})[row.z] || 0)
              }
            };
            return acc;
          }, {});
          data = Object.keys(data).sort().map(xVal => ({
            x: xVal,
            ...data[xVal]
          }));
          let keyMax = 0;
          const mostKeys = data.reduce((mostKeys, row) => {
            const keys = Object.keys(row).filter(key => key !== 'x');

            if (keys.length > keyMax) {
              keyMax = keys.length;
              return keys;
            }

            return mostKeys;
          }, []);
          data = data.filter(row => mostKeys.every(key => Object.keys(row).indexOf(key) !== -1));
          x.domain(_d.default.extent(data, d => d.x));
          const stackedData = stack(data);
          y.domain(_d.default.extent(stackedData.reduce((acc, section) => acc.concat(section.reduce((secAcc, point) => secAcc.concat([point[0], point[1]]), [])), [])));
          const layer = gChart.selectAll('.layer').data(stackedData);
          const cents = data.reduce((cents, row) => {
            cents[row.x] = keys.reduce((cent, key) => cent + row[key], 0);
            return cents;
          }, {});
          let hoverYear = data[0].x;
          let lastHoverYear = hoverYear;
          const hoverThreshold = 50;

          const __parentScope = this;

          layer.enter().append('path').attr('class', 'area').style('fill', d => colors[d.key]).attr('d', area).each(function (d) {
            this._current = d;
          }).on('mouseover', renderToolTip).on('mouseout', () => {
            tooltip.style('display', 'none');
            bar.style('display', 'none');
          }).on('mousemove', function (d) {
            const [x, y] = _d.default.mouse(this);

            const snap = Math.floor(x / width / (1.0 / d.length));
            const xPos = width / (d.length - 1) * snap;

            if (Math.abs(xPos - x) < hoverThreshold) {
              lastHoverYear = d[snap].data.x;

              if (lastHoverYear !== hoverYear) {
                hoverYear = lastHoverYear;
                renderToolTip(d);
              }

              tooltip.style('display', 'block');
              bar.style('display', 'block');
              bar.attr('x1', xPos).attr('x2', xPos);
              tooltip.style('top', `${y * (height / __parentScope.container.height) - 40}px`);

              if (xPos > width / 2) {
                tooltip.style('left', `${xPos - margin.right * 3}px`);
              } else {
                tooltip.style('left', `${xPos + margin.left}px`);
              }
            } else {
              tooltip.style('display', 'none');
              bar.style('display', 'none');
            }
          });

          function renderToolTip(d) {
            const criteria = d.key;
            const [sector, fuelType] = criteria.split('-');
            const value = d.filter(yearRow => yearRow.data.x == hoverYear)[0].data[criteria];
            const percent = Math.round(value / cents[hoverYear] * 100);
            tooltip.select('.sector').html((0, _capitalize.default)(sector));
            tooltip.select('.fuel-type').html(_fuelTypes.fuelTypesMap[fuelType]);
            tooltip.select('.value').html(`${yAxisConf.format(Math.round(value))} ${yAxisConf.label}`);
            tooltip.select('.percent').html(`${percent}%`).style('color', colors[criteria]);
            tooltip.style('display', 'block');
            bar.style('display', 'block');
          }

          const xAxis = _d.default.axisBottom(x).ticks(data.length - 1).tickSize(0).tickPadding(10).tickFormat(xAxisConf.format);

          const yAxis = _d.default.axisLeft(y).ticks(yAxisConf.ticks).tickSize(0).tickPadding(10).ticks(10).tickFormat(yAxisConf.format);

          gChart.append('g').attr('class', 'axis axis-x').attr('transform', `translate(0, ${height})`).call(xAxis);
          gChart.append('g').attr('class', 'axis axis-y').attr('transform', `translate(-${margin.left / 5},0)`).call(yAxis);
          this.chart.append('text').attr('class', 'axis-label').attr('x', height / -2 - margin.top).attr('y', 2).attr('transform', 'rotate(-90)').attr("dy", "12").style('text-anchor', 'middle').text(yAxisConf.label);
          this.chart.append('text').attr('class', 'axis-label').attr('x', width / 2 + margin.left).attr('y', height + margin.top + margin.bottom - 22).attr("dy", "12").style('text-anchor', 'middle').text(xAxisConf.label);

          if (!chartData.length) {
            const placeholder = gChart.append('g');
            placeholder.append('text').attr('class', 'missing-data').attr('x', width / 2).attr('y', height / 2 - 12).attr("dy", "12").style('text-anchor', 'middle').text('Oops! We can\'t find this data right now.');
            placeholder.append('text').attr('class', 'missing-data').attr('x', width / 2).attr('y', height / 2 + 12).attr("dy", "12").style('text-anchor', 'middle').text('Please try again later.');
          }

          const bar = gChart.append('line').attr('class', 'tooltip-bar').attr('y1', '0').attr('y2', height);

          const legendFormat = key => _fuelTypes.fuelTypesMap[key.split('-')[1]];

          this.get('legend').selectAll('*').remove();
          (0, _charts.drawLegend)(this.get('legend'), key => colors[key], keys, legendFormat);
        }

      }]
    };
  }, Ember.Component);

  _exports.default = StackedAreaChartComponent;
});