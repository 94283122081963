define("lead-dashboard/helpers/uppercase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uppercase = uppercase;
  _exports.default = void 0;

  function uppercase(params) {
    return params[0] ? params[0].toUpperCase() : params[0];
  }

  var _default = Ember.Helper.helper(uppercase);

  _exports.default = _default;
});