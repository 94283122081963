define("lead-dashboard/utils/maps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.huTypeMap = void 0;
  const huTypeMap = {
    'u1a': 'Single-Family, Attached',
    'u1d': 'Single-Family, Detached',
    'u2_4': 'Multi-Family, 2-4 Units',
    'u5ov': 'Multi-Family, 5+ Units',
    'u_oth': 'Other'
  };
  _exports.huTypeMap = huTypeMap;
  const maps = {
    huTypeMap: huTypeMap
  };
  var _default = maps;
  _exports.default = _default;
});