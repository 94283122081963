define("lead-dashboard/routes/city/residential", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Route {
    model() {
      const cityModel = this.modelFor('city');
      return Ember.RSVP.hash({
        data: cityModel.sectorData['residential'],
        municipality: cityModel.municipality
      });
    }

    afterModel(model) {
      const latestYear = Math.max(...model.data.rows.map(row => row.year));
      model['sectorData'] = {};
      model['sectorData']['rows'] = model.data.rows.filter(row => row.year === latestYear);
    }

  }

  _exports.default = _default;
});