define("lead-dashboard/utils/transform-query-params", ["exports", "ember-transition-helper/utils/transform-query-params"], function (_exports, _transformQueryParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _transformQueryParams.default;
    }
  });
});