define("lead-dashboard/controllers/city", ["exports", "lead-dashboard/utils/sectors"], function (_exports, _sectors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class _default extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.sectors = _sectors.sectorRouteMap;
    }

  }

  _exports.default = _default;
});