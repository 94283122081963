define("lead-dashboard/templates/components/pie-chart-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sE3DcRN+",
    "block": "{\"symbols\":[\"criterion\",\"metric\"],\"statements\":[[7,\"section\"],[11,\"class\",\"component pie-chart-layout content-wrapper\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"chart-modifiers\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"\\n      \"],[1,[27,\"acronyze\",[[23,[\"municipality\"]]],null],false],[0,\"'s Energy\\n\\n      \"],[7,\"span\"],[11,\"class\",\"power-select\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"onchange\"],[[23,[\"metrics\"]],[23,[\"selectedMetric\"]],[27,\"action\",[[22,0,[]],\"changeChartMetric\"],null]]],{\"statements\":[[0,\"          \"],[1,[22,2,[]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"h5\"],[9],[0,\"by Fuel Source\"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"criteria-selector\"],[9],[0,\"\\n      \"],[7,\"h4\"],[9],[0,\"Type of \"],[1,[21,\"criteriaName\"],false],[0,\": \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"select-wrapper\"],[9],[0,\"\\n        \"],[7,\"select\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"changeChartCriteria\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n          \"],[7,\"option\"],[11,\"value\",\"all\"],[11,\"selected\",\"\"],[9],[0,\"All\"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"chartCriteria\"]]],null,{\"statements\":[[0,\"            \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[9],[1,[27,\"capitalize\",[[22,1,[]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"chart-wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"pie-chart\",null,[[\"data\",\"metric\",\"title\"],[[23,[\"chartData\"]],[23,[\"metric\"]],\"Fuel Chart\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "lead-dashboard/templates/components/pie-chart-layout.hbs"
    }
  });

  _exports.default = _default;
});